import React, { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import moment from "moment";
import _ from "lodash";
import { useDispatch } from "react-redux";

import Clients from "../Clients";
import { searchInvoices } from "../../redux/actions";

const { TextArea } = Input;
const { Option } = Select;

export default ({ form, defaultValues = {}, invoice = false }) => {
  const intl = useIntl();

  const [client, setClient] = useState(defaultValues.client);
  const [clientModalVisible, setClientModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [invoiceData, setInvoiceData] = useState({ data: [], total: 0 });
  const [loadingInvoices, setLoadingInvoices] = useState(false);

  const requiredRule = {
    required: true,
    message: intl.formatMessage({ id: "fieldRequired" }),
  };

  const renderClientName = () => {
    if (!client) return intl.formatMessage({ id: "selectClient" });
    return `${client.firstName} ${client.lastName} [${client.companyName}]`;
  };

  const onClientSelection = (value, row) => {
    setClient(row[0]);
    form.setFieldsValue({ client: row[0]._id });
    setLoadingInvoices(true);
    dispatch(
      searchInvoices(
        1,
        9999999,
        { client: row[0]._id, paid: false, status: "issued" },
        { id: 1 }
      )
    ).then(({ payload }) => {
      setLoadingInvoices(false);
      updateAmountToPay(payload);
    });
    setClientModalVisible(false);
  };

  const paymentMethods = [
    "cash",
    "cheque",
    "creditCard",
    "bankTransfer",
    "moneyOrder",
    "other",
  ];

  const invoicesColumns = [
    {
      title: intl.formatMessage({ id: "ref" }),
      dataIndex: "_ref",
    },
    {
      title: intl.formatMessage({ id: "date" }),
      render: (record) => dateRenderer(record.date),
    },
    {
      title: intl.formatMessage({ id: "invoiceType" }),
      render: (record) =>
        record.invoiceType
          ? intl.formatMessage({ id: record.invoiceType })
          : "",
    },
    {
      title: intl.formatMessage({ id: "balance" }),
      render: (record) => renderBalance(record),
    },
    {
      title: intl.formatMessage({ id: "amountToPay" }),
      dataIndex: "amountToPay",
      editable: true,
      inputType: "number",
      inputProps: (record) => ({
        min: 0,
        max: getBalance(record),
        precision: 2,
        disabled: !!invoice,
      }),
    },
  ];

  const getBalance = (record) =>
    _.round(
      _.get(record, "breakdown.total", 0) - _.get(record, "amountPaid", 0),
      2
    );

  const formatFormInvoiceData = (invoiceData) => {
    return invoiceData.data.map(d => ({ _id: d._id, amount: d.amountToPay }));
  };

  const handleSave = (row) => {
    const newData = [...invoiceData.data];
    const index = newData.findIndex((item) => row._ref === item._ref);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    const newInvoiceData = {
      ...invoiceData,
      data: newData,
    };
    setInvoiceData(newInvoiceData);
    form.setFieldsValue({ invoices: formatFormInvoiceData(newInvoiceData) });
  };

  const updateAmountToPay = (invoiceData) => {
    const amount = form.getFieldValue("amount") || 0;
    const newInvoiceData = {
      ...invoiceData,
      data: invoiceData.data.reduce(
        (acc, curr) => {
          const balance = getBalance(curr);
          const amountToPay = Math.min(balance, acc.amount);
          const remainingAmount = _.round(acc.amount - amountToPay, 2);
          acc.res.push({
            ...curr,
            amountToPay,
          });
          acc.amount = remainingAmount;
          return acc;
        },
        { res: [], amount: _.round(amount, 2) }
      ).res
    };
    setInvoiceData(newInvoiceData);
    form.setFieldsValue({ invoices: formatFormInvoiceData(newInvoiceData) });
  };

  const onValuesChange = (changedValues) => {
    if (changedValues.amount) {
      updateAmountToPay(invoiceData);
    }
  };

  const totalInvoiceAmount = _.round(
    _.sum(invoiceData.data.map((d) => getBalance(d))),
    2
  );

  useEffect(() => {
    if (!!invoice) {
      const amount = form.getFieldValue("amount");
      invoice.amountPaid = invoice.amountPaid - amount;
      onValuesChange({ amount })
      updateAmountToPay({ data: [invoice] })
    }
  }, [invoice]);

  return (
    <>
      <Form
        name="paymentForm"
        layout="vertical"
        form={form}
        onValuesChange={onValuesChange}
        initialValues={{
          transactionDate: moment(),
          ...defaultValues,
          client: _.get(defaultValues, "client._id"),
        }}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name="client"
              label={intl.formatMessage({ id: "client" })}
              rules={[requiredRule]}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Input value={renderClientName()} disabled />
                <Button
                  type="primary"
                  onClick={() => setClientModalVisible(true)}
                  disabled={!!invoice}
                >
                  {intl.formatMessage({ id: "select" })}
                </Button>
              </div>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={intl.formatMessage({ id: "transactionDate" })}
              name="transactionDate"
              rules={[requiredRule]}
            >
              <DatePicker
                disabled={!!invoice}
                allowClear
                style={{ width: "100%" }}
                format={"DD/MM/YYYY"}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={intl.formatMessage({ id: "paymentMethod" })}
              name="method"
              rules={[requiredRule]}
            >
              <Select disabled={!!invoice}>
                {paymentMethods.map((m) => (
                  <Option key={m}>{intl.formatMessage({ id: m })}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={intl.formatMessage({ id: "amount" })}
              name="amount"
            >
              <InputNumber
                min={0}
                max={totalInvoiceAmount}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={intl.formatMessage({ id: "addRef" })}
              name="addRef"
              valuePropName="checked"
            >
              <Switch disabled={!!invoice}/>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="invoices" label={intl.formatMessage({ id: "invoices" })} rules={[requiredRule]}>
          <Table
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell,
              },
            }}
            rowKey={(record) => record._ref}
            dataSource={invoiceData.data}
            columns={invoicesColumns.map((col) => {
              if (!col.editable) {
                return col;
              }
              return {
                ...col,
                onCell: (record) => ({
                  record,
                  ...col,
                  handleSave,
                }),
              };
            })}
            loading={loadingInvoices}
            rowClassName={() => "editable-row"}
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: "notes" })} name="notes">
          <TextArea allowClear rows={3} />
        </Form.Item>
      </Form>
      <Modal
        bodyStyle={{ padding: 0 }}
        footer={null}
        onCancel={() => setClientModalVisible(false)}
        title={intl.formatMessage({ id: "selectClient" })}
        visible={clientModalVisible}
        width="75%"
        mask={true}
      >
        <Clients hideHeader={true} onRowSelection={onClientSelection} />
      </Modal>
    </>
  );
};

const dateRenderer = (date) => {
  if (!date) return;
  return moment(date).format("DD/MM/YYYY");
};

const renderBalance = (record) => {
  const total = _.get(record, "breakdown.total");
  const amountPaid = record.amountPaid || 0;
  return `€${_.round(total - amountPaid, 2).toFixed(2)}`;
};

const EditableContext = React.createContext(null);

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  inputType = "text",
  inputProps = () => {},
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {inputType === "number" ? (
          <InputNumber {...inputProps(record)} ref={inputRef} onBlur={save} />
        ) : (
          <Input ref={inputRef} onBlur={save} />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
