import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button } from "antd";

import ArchiveTable from "../../components/ArchiveTable";

export default ({ fetchData, data, onRowSelection }) => {
  const intl = useIntl();
  const history = useHistory();

  const columns = [
    {
      title: intl.formatMessage({ id: "actions" }),
      className: "actions-column",
      render: (record) => (
        <div>
          <Button
            type="link"
            onClick={() => history.push(`/clients/${record._id}`)}
            style={{ marginRight: 8 }}
          >
            {intl.formatMessage({ id: "view" })}
          </Button>
          <Button type="link" onClick={() => history.push(`/clients/edit/${record._id}`)}>
            {intl.formatMessage({ id: "edit" })}
          </Button>
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: "ref" }),
      dataIndex: "_ref",
    },
    {
      title: intl.formatMessage({ id: "firstName" }),
      dataIndex: "firstName",
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: "lastName" }),
      dataIndex: "lastName",
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: "company" }),
      dataIndex: "companyName",
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: "street" }),
      dataIndex: "street",
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phoneNumber",
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: "subscriptionInvoice" }),
      render: (record) =>
        record.subscriptionInvoice?.amount ? `€${record.subscriptionInvoice.amount}` : null,
    },
  ];

  return (
    <ArchiveTable
      fetchData={fetchData}
      columns={columns}
      data={data}
      onRowSelection={onRowSelection}
    />
  );
};
