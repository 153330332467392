import React, { useState } from "react";
import { Button, Form, PageHeader } from "antd";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createClient, createPayment } from "../../../redux/actions";
import PaymentForm from "../PaymentForm";

export default (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    setLoading(true);
    dispatch(createPayment(values))
      .then(() => history.push("/payments"))
      .catch(() => setLoading(false));
  };

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "createPayment" })}
        extra={[
          <Button disabled={loading} key="create" type="primary" onClick={onSubmit}>
            {intl.formatMessage({ id: "create" })}
          </Button>,
        ]}
      />
      <PaymentForm form={form} />
    </div>
  );
};
